// import React from 'react';
import { gql } from '@apollo/client';
import { getUserIdFieldName, useRealmApp } from '../../components/RealmApp';
import { useState } from 'react';
import { useApolloClient } from '../useApolloClient';
import watchOne from '../watch/watchOne';
import {
  UserCustomData,
  UserCustomDataCollections,
  UserCustomDataUnion,
  UserTypes,
} from '@mymeeinc/types/user';
import useSettings from 'minimal-ui/hooks/useSettings';
import uuidv4 from 'minimal-ui/utils/uuidv4';
import { UseUserEditable } from './queries/types';
import queries from './queries';
import { useSmartFetch } from '../useSmartFetch';
import {
  CoachCustomDatum,
  MemberCustomDatum,
  userTypeToCustomData,
} from '@mymeeinc/types/customData';
import { useUsers } from './useUsers';
import _ from 'lodash';

type UseUserHookInput = {
  userType: UserTypes;
  userId?: string | null;
  callerRef: string;
  noAutoFetch?: boolean;
};

const subFieldInjector = (field: string | Array<any>) => {
  if (Array.isArray(field)) {
    const [fieldName, subfields] = field;
    return `${fieldName} {
                      ${subfields.join('\n')}
                  }`;
  }
  return field;
};

export type EditUserMethod = (userId: string, formData: UserCustomDataUnion) => Promise<void>;

export const useMember = (
  member_id?: string | null,
  callerRef = `useMember:${uuidv4()}`
): {
  user: MemberCustomDatum | null;
  isLoading: boolean;
} =>
  useUser({
    userType: UserTypes.MEMBER,
    userId: member_id,
    callerRef,
  });
export const useCoach = (
  coach_id?: string | null,
  callerRef = `useCoach:${uuidv4()}`
): {
  user: CoachCustomDatum | null;
  isLoading: boolean;
} =>
  useUser({
    userType: UserTypes.COACH,
    userId: coach_id,
    callerRef,
  });
export const useAdmin = (admin_id?: string | null, callerRef = `useAdmin:${uuidv4()}`) =>
  useUser({
    userType: UserTypes.ADMIN,
    userId: admin_id,
    callerRef,
  });

export function useUser({ userType, userId, callerRef, noAutoFetch = false }: UseUserHookInput): {
  user: UserCustomDataUnion | null;
  isLoading: boolean;
} {
  // Get a graphql client and set up a list of user in state
  const { setIsLoading: _setIsLoading } = useRealmApp();
  const { getCache } = useUsers({ userType, callerRef, noAutoFetch: true });
  const { project } = useSettings();
  const graphql = useApolloClient();
  const [isLoadingInternal, setIsLoadingInternal] = useState<boolean>(true);
  const [userObject, setUserObject] = useState<UserCustomData | null>(null);
  const queryInfos: UseUserEditable = (queries[project] as any)[userType];
  const componentIdentifier = ['useUser', userType].join(':');
  const setIsLoading = (identifier: string) => (isLoading: boolean) => {
    setIsLoadingInternal(isLoading);
    return _setIsLoading(identifier)(isLoading);
  };
  const fetchOneUser = async <UserCustomData>(): Promise<UserCustomData> => {
    const setLoading = setIsLoading(componentIdentifier + ':fetch');
    try {
      setLoading(true);
      if (userId) {
        const cached = await getCache((row: any) => row[getUserIdFieldName(userType)] === userId);
        if (cached) {
          return cached;
        }
      }
      const actualQuery = queryInfos.queryWithId;
      const query = gql`
        query ${actualQuery.queryInput} {
          ${actualQuery.queryName} {
            ${actualQuery.readFields.map(subFieldInjector).join('\n')}
          }
        }
      `;
      const { data } = await graphql.query({
        query,
        variables: { [getUserIdFieldName(userType)]: userId },
      });
      const outputField = actualQuery.outputField;
      return data[outputField];
    } catch (e) {
      console.error(e);
    } finally {
      _.debounce(() => setLoading(false), 200)();
    }
    return {} as UserCustomData;
  };

  useSmartFetch(componentIdentifier, {
    noAutoFetch,
    set: (customData: UserCustomData) => {
      setUserObject(customData);
      setIsLoadingInternal(false);
    },
    fetcher: fetchOneUser,
    watcher: watchOne,
    injector: (user: UserCustomDataUnion): UserCustomDataUnion => {
      return {
        __typename: userTypeToCustomData(userType),
        ...user,
      } as UserCustomDataUnion;
    },
    collectionName: UserCustomDataCollections[userType],
    key: userId ?? 'own',
    callerRef,
  });

  if (!userId) {
    return {
      user: null,
      isLoading: isLoadingInternal,
    };
  }

  return {
    user: userObject as UserCustomDataUnion,
    isLoading: isLoadingInternal,
  };
}
