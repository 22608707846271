// import React from 'react';
import ReactDOM from 'react-dom/client';
// i18n
import 'minimal-ui/locales/i18n';

// highlight
import 'minimal-ui/utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// redux
import { store } from 'minimal-ui/redux/store';
// error reporting
import * as Sentry from '@sentry/react';

// contexts
import { SettingsProvider } from 'minimal-ui/contexts/SettingsContext';
import { CollapseDrawerProvider } from 'minimal-ui/contexts/CollapseDrawerContext';
import { RealmAppProvider } from 'shared-web/components/RealmApp';
import realm_dev_config from './realm.json';
import realm_staging_config from './realm-staging.json';
import realm_prod_config from './realm-prod.json';
import App from './App';
import { defaultSettings } from './config';
import { ProgressBarStyle } from 'minimal-ui/components/ProgressBar';
import { ChartStyle } from 'minimal-ui/components/chart';
import ScrollToTop from 'minimal-ui/components/ScrollToTop';

// theme
import ThemeProvider from 'minimal-ui/theme';
// components
import ThemeSettings from 'minimal-ui/components/settings';
import NotistackProvider from 'minimal-ui/components/NotistackProvider';
import MotionLazyContainer from 'minimal-ui/components/animate/MotionLazyContainer';

import { name, version } from '../package.json';

const versionPostfix =
  location.hostname === 'localhost'
    ? '-localhost'
    : import.meta.env.MODE === 'development'
    ? `-${import.meta.env.VITE_GITHUB_SHA}`
    : '';

Sentry.init({
  enabled: location.hostname !== 'localhost',
  environment: import.meta.env.MODE,
  debug: import.meta.env.MODE !== 'production',
  release: `${name}@${version}${versionPostfix}`,
  dsn: 'https://b054f239f1074a1eb792d640ef88ada0@o685251.ingest.sentry.io/4505346761818112',
  integrations: [new Sentry.BrowserTracing({})],
  tracesSampleRate: import.meta.env.MODE !== 'production' ? 1.0 : 0.2,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <SettingsProvider defaultSettings={defaultSettings}>
    <RealmAppProvider
      dev={realm_dev_config}
      staging={realm_staging_config}
      prod={realm_prod_config}
    >
      <HelmetProvider>
        <ReduxProvider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CollapseDrawerProvider>
              <BrowserRouter>
                <MotionLazyContainer>
                  <ThemeProvider>
                    <ThemeSettings>
                      <NotistackProvider>
                        <ProgressBarStyle />
                        <ChartStyle />
                        <ScrollToTop />
                        <App />
                      </NotistackProvider>
                    </ThemeSettings>
                  </ThemeProvider>
                </MotionLazyContainer>
              </BrowserRouter>
            </CollapseDrawerProvider>
          </LocalizationProvider>
        </ReduxProvider>
      </HelmetProvider>
    </RealmAppProvider>
  </SettingsProvider>
);
