import { gql } from '@apollo/client';
import { useState } from 'react';

import { useApolloClient } from 'shared-web/hooks/useApolloClient';
import {
  InsertSessionFn,
  RemoveSessionFn,
  Session,
  SessionHookFn,
  UpdateSessionFn,
} from '@mymeeinc/types/session';
import watchMany from './watch/watchMany';
import { useRealmApp } from '../components/RealmApp';
import useSettings from 'minimal-ui/hooks/useSettings';
import { UserTypes } from '@mymeeinc/types/user';
import { WriteFields } from './users/rules/types';
import { gqlMutationInputFields, gqlMutationSetFields } from '../utils';
import { useSmartFetch } from './useSmartFetch';

export function useSession(member_id: string = 'all'): SessionHookFn {
  const { setIsLoading: _setIsLoading } = useRealmApp();
  const graphql = useApolloClient();
  const [isLoadingInternal, setIsLoadingInternal] = useState<boolean>(true);
  const { appUserType } = useSettings();

  const componentIdentifier = ['useSessions', member_id].join(':');
  const [list, setList] = useState<Session[]>([]);

  const fetcher = async () => {
    const setLoading = setIsLoading(componentIdentifier);
    try {
      setLoading(true);
      let a = '';
      let b = '';
      let c = {};
      if (member_id !== 'all') {
        a = `($member_id: String!)`;
        b = `(query: { member_id: $member_id })`;
        c = { member_id };
      }
      const query = gql`
        query getAllSessions${a} {
          sessions${b} {
            _id
            coach_id
            member_id
            created_by
            created_at
            acuity {
              canceled_by
              cancellation_date
              created_at
              confirmationPage
              datetime
              id
            }
            expiration_date
            status
          }
        }
      `;
      const { data } = await graphql.query({ query, variables: { ...c } });
      const { sessions } = data;
      return sessions;
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const { invalidateCache } = useSmartFetch(componentIdentifier, {
    set: (list: Session[]) => {
      setList(list);
      setIsLoadingInternal(false);
    },
    fetcher,
    watcher: watchMany,
    collectionName: 'sessions',
    key: member_id,
    callerRef: componentIdentifier,
  });

  const setIsLoading = (identifier: string) => (isLoading: boolean) => {
    setIsLoadingInternal(isLoading);
    return _setIsLoading(identifier)(isLoading);
  };

  const insert: InsertSessionFn = async ({ member_id, coach_id, expiration_date, status }) => {
    const setLoading = setIsLoading(componentIdentifier + ':Insert');
    try {
      invalidateCache();
      setLoading(true);
      const result = await graphql.mutate({
        mutation: gql`
          mutation insertSession(
            $member_id: String!
            $coach_id: String!
            $expiration_date: DateTime
            $created_at: DateTime
            $status: String!
          ) {
            insertOneSession(
              data: {
                member_id: $member_id
                coach_id: $coach_id
                expiration_date: $expiration_date
                created_at: $created_at
                status: $status
                acuity: {}
              }
            ) {
              _id
            }
          }
        `,
        variables: {
          member_id,
          coach_id,
          expiration_date,
          created_at: new Date(),
          status,
        },
      });
      console.log({ result });
      return { success: true, msg: '' };
    } catch (err: any) {
      if (err.message.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
      return { success: false, msg: err.message };
    } finally {
      setLoading(false);
    }
  };
  const update: UpdateSessionFn = async (data) => {
    console.log({ data });
    const setLoading = setIsLoading(componentIdentifier + ':Update');

    try {
      invalidateCache();
      setLoading(true);
      const { _id, expiration_date, status } = data;

      const fields: WriteFields = [['status', 'String!']];
      if (appUserType === UserTypes.ADMIN) {
        fields.push(['expiration_date', 'DateTime']);
      }
      const result = await graphql.mutate({
        mutation: gql`
          mutation updateSession($_id: ObjectId!, ${gqlMutationInputFields(fields)}) {
            updateOneSession(
              query: { _id: $_id }
              set: { ${gqlMutationSetFields(fields)} }
            ) {
              _id
            }
          }
        `,
        variables: { _id, expiration_date, status },
      });
      console.log({ result });
      return { success: true, msg: '' };
    } catch (err: any) {
      if (err.message.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
      return { success: false, msg: err.message };
    } finally {
      setLoading(false);
    }
  };

  const remove: RemoveSessionFn = async ({ _id }) => {
    const setLoading = setIsLoading(componentIdentifier + ':Remove');

    try {
      invalidateCache();
      setLoading(true);

      const result = await graphql.mutate({
        mutation: gql`
          mutation deleteOneSession($_id: ObjectId) {
            deleteOneSession(query: { _id: $_id }) {
              _id
            }
          }
        `,
        variables: { _id },
      });
      console.log({ result });
      return { success: true, msg: '' };
    } catch (err: any) {
      if (err.message.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
      return { success: false, msg: err.message };
    } finally {
      setLoading(false);
    }
  };

  return {
    insert,
    update,
    remove,
    loading: isLoadingInternal,
    list,
  };
}
