import { ObjectId } from 'bson';

export enum SessionStatuses {
  UNSCHEDULED = 'Unscheduled',
  SCHEDULED = 'Scheduled',
  CLIENT_MISSED = 'Client missed',
  COACH_MISSED = 'Coach missed',
  COMPLETED = 'Completed',
  EXPIRED = 'Expired',
  CANCELED = 'Canceled',
}

export type AcuityAppointment = {
  id?: number;
  created_at?: Date;
  datetime?: Date;
  cancellation_date?: Date;
  canceled_by?: string;
  confirmationPage?: string;
};
export type Session = {
  _id: ObjectId;
  acuity: AcuityAppointment;
  coach_id: string;
  member_id: string;
  created_by: string;
  created_at: Date;
  expiration_date?: Date;
  status: SessionStatuses;
};

export type SessionHookFn = {
  insert: InsertSessionFn;
  update: UpdateSessionFn;
  remove: RemoveSessionFn;
  loading: boolean;
  list: Session[];
};

export type UpdateSessionInput = {
  _id: ObjectId;
  member_id: string;
  coach_id: string;
  expiration_date?: Date;
  status: string;
};

export type InsertSessionInput = {
  member_id: string;
  coach_id: string;
  expiration_date?: Date;
  status?: SessionStatuses;
};

export type UpdateSessionOutput = {
  success: boolean;
  msg: string;
};

export type InsertSessionOutput = {
  success: boolean;
  msg: string;
};

export type RemoveSessionInput = {
  _id: ObjectId;
};
export type RemoveSessionOutput = {
  success: boolean;
  msg: string;
};
export type InsertSessionFn = (props: InsertSessionInput) => Promise<InsertSessionOutput>;
export type UpdateSessionFn = (props: UpdateSessionInput) => Promise<UpdateSessionOutput>;
export type RemoveSessionFn = (props: RemoveSessionInput) => Promise<RemoveSessionOutput>;
