import { format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Tooltip, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
// components
import Label from 'minimal-ui/components/Label';
import Iconify from 'minimal-ui/components/Iconify';
import { MemberCustomDatum } from '@mymeeinc/types/customData';
import { Session, SessionStatuses } from '@mymeeinc/types/session';
import SvgIconStyle from 'minimal-ui/components/SvgIconStyle';
import { useRealmApp } from 'shared-web/components/RealmApp';
import useToggle from 'minimal-ui/hooks/useToggle';
import { UserTypes } from '@mymeeinc/types/user';
import { useCoach } from '../../hooks/users/useUser';
import { RHFTextField, RHFSwitch } from 'minimal-ui/components/hook-form';
import ConfirmationDialog, { ConfirmationDialogRef } from 'shared-web/sections/ConfirmationDialog';
import { useRef } from 'react';
import * as Yup from 'yup';

// ----------------------------------------------------------------------

export const getStatusLabelColor = (status: SessionStatuses) => {
  switch (status) {
    case SessionStatuses.SCHEDULED:
      return 'info';
    case SessionStatuses.COMPLETED:
      return 'success';
    case SessionStatuses.COACH_MISSED:
      return 'warning';
    case SessionStatuses.CLIENT_MISSED:
      return 'error';
    case SessionStatuses.EXPIRED:
      return 'error';
    case SessionStatuses.CANCELED:
      return 'error';
  }
};

export const StatusLabel = ({ status }: { status: SessionStatuses }) => {
  const {
    palette: { mode },
  } = useTheme();
  return (
    <Label
      variant={mode === 'light' ? 'ghost' : 'filled'}
      color={getStatusLabelColor(status) ?? 'default'}
    >
      {status ?? 'Unscheduled'}
    </Label>
  );
};
export const ScheduledIcon = ({ link }: { link: string }) => {
  const theme = useTheme();

  return (
    <Tooltip title={'Appointment details'}>
      <RouterLink to={link} target="_blank">
        <SvgIconStyle
          sx={{ bgcolor: theme.palette.info.darker }}
          src={`/assets/icons/navbar/ic_booking.svg`}
        />
      </RouterLink>
    </Tooltip>
  );
};

type FormValuesProps = {
  note?: string;
  noShow?: boolean;
};

type MySessionsListRowProps = {
  session: Session;
  sessionNo: number;
  member?: MemberCustomDatum;
  onEdit?: (session: Session) => void;
};

export default function MySessionsListRow({
  session,
  member,
  sessionNo,
  onEdit,
}: MySessionsListRowProps) {
  const ref = useRef<ConfirmationDialogRef>();
  const { realmApp, userType } = useRealmApp();
  const { enqueueSnackbar } = useSnackbar();
  const { toggle: openConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useToggle();
  const { user: coach } = useCoach(session.coach_id, 'MySessionsListRow');
  const { _id: sessionId, expiration_date, status = SessionStatuses.UNSCHEDULED, acuity } = session;
  const {
    id: acuity_appointment_id,
    datetime: acuity_appointment_datetime,
    confirmationPage: acuity_appointment_confirmationPage,
  } = acuity || {};
  const dateFormatted = !acuity_appointment_datetime
    ? '-'
    : format(new Date(acuity_appointment_datetime), 'dd MMM yyyy HH:mm');

  const onSubmit = async (data: FormValuesProps) =>
    new Promise((resolve, reject) => {
      realmApp.currentUser?.functions
        .cancelAppointment(sessionId, data.note, data.noShow)
        .then((data) => {
          if (data.success) {
            resolve(true);
            enqueueSnackbar('Appointment canceled.');
            ref.current?.reset();
          } else {
            enqueueSnackbar(data.msg, { variant: 'error' });
            reject(data.msg);
          }
        })
        .catch((e: any) => {
          enqueueSnackbar(e.message, { variant: 'error' });
          reject(e);
        })
        .finally(() => {
          onCloseConfirm();
        });
    });
  return (
    <TableRow>
      <TableCell>{sessionNo + 1}</TableCell>
      <TableCell sx={{ textAlign: 'center' }}>{dateFormatted}</TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        {!expiration_date ? '-' : format(new Date(expiration_date), 'dd MMM yyyy HH:mm')}
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            alt={[coach?.first_name, coach?.last_name].join(' ')}
            src={coach?.picture}
            sx={{ width: 30, height: 30 }}
          />
          <Typography variant="subtitle2">
            {[coach?.first_name, coach?.last_name].join(' ')}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>
        <StatusLabel status={status} />
      </TableCell>
      <TableCell align="right">
        {acuity_appointment_confirmationPage && (
          <ScheduledIcon link={acuity_appointment_confirmationPage} />
        )}
      </TableCell>
      <TableCell align="right">
        {acuity_appointment_id && status === SessionStatuses.SCHEDULED && (
          <>
            <ConfirmationDialog
              formShape={{
                note: Yup.string().required('Note is required'),
                noShow: Yup.bool(),
              }}
              ref={ref}
              defaultValues={{
                note: '',
                noShow: false,
              }}
              question={
                <>
                  Are you sure you want to cancel{' '}
                  {userType === UserTypes.MEMBER
                    ? 'your'
                    : `${[member?.first_name, member?.last_name].join(' ')}'s`}{' '}
                  appointment on <strong>{dateFormatted}</strong>?
                </>
              }
              info={
                userType === UserTypes.MEMBER && (
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    You can schedule another one after cancellation.
                  </Typography>
                )
              }
              extraFields={
                <>
                  <Stack spacing={2} sx={{ px: 3, mt: 3 }}>
                    <RHFTextField name="note" label="Cancellation Note" />
                  </Stack>
                  {userType !== UserTypes.MEMBER && (
                    <Stack spacing={2} sx={{ px: 3, mt: 3 }}>
                      <RHFSwitch name="noShow" label="No show?" />
                    </Stack>
                  )}
                </>
              }
              onSubmit={onSubmit}
              openConfirm={openConfirm}
              onCloseConfirm={onCloseConfirm}
            />
            <Tooltip title={'Cancel appointment'}>
              <RouterLink to="" onClick={onOpenConfirm}>
                <Iconify icon={'ic:twotone-free-cancellation'} color="red" width={24} height={24} />
              </RouterLink>
            </Tooltip>
          </>
        )}
      </TableCell>

      {onEdit && (
        <TableCell align="right">
          <Tooltip title={'Edit'}>
            <RouterLink to="" onClick={() => (onEdit ? onEdit(session) : () => {})}>
              <IconButton>
                <Iconify icon={'eva:edit-fill'} width={20} height={20} />
              </IconButton>
            </RouterLink>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
}
