import { DeleteUserTagFn, ImpactType, InsertUserTagFn } from './tags';
import { ObjectId } from 'bson';

export interface Food {
  _id: ObjectId;
  name: string;
  parent_id: string;
  is_enabled: boolean;
  image?: string | null;
  tagged_user_count: number;
}

export interface Symptom {
  _id: ObjectId;
  name: string;
  parent_id?: string;
  is_enabled: boolean;
  image?: string | null;
  tagged_user_count: number;
  is_generic?: boolean;
}

export interface Diagnosis {
  _id: ObjectId;
  name: string;
  parent_id?: string;
  is_enabled: boolean;
  image?: string | null;
  tagged_user_count: number;
  symptom_ids: string[];
}

export interface DayToDay {
  _id: ObjectId;
  name: string;
  parent_id?: string;
  is_enabled: boolean;
  is_question: boolean;
  image?: string | null;
  tagged_user_count: number;
  impact: ImpactType;
  inputs?: ContentInput[];
}

export enum ContentInputType {
  Text = 'text',
  Number = 'number',
}

export interface ContentInput {
  name: string;
  type: ContentInputType;
  required: boolean;
}
export enum ContentTypes {
  Diagnosis = 'diagnosis',
  Symptom = 'symptom',
  Food = 'food',
  DayToDay = 'day_to_day',
}

export type ContentTypeUnion = Food & Symptom & Diagnosis & DayToDay;
export type ContentRow = ContentTypeUnion & {
  display_name: string;
  __typename?: string;
};

export type ContentInsertFn = (props: {
  name: string;
  parent_id: string;
  is_enabled: boolean;
  image?: string | null;
}) => Promise<boolean | string>;
export type ContentUpdateFn = (props: {
  _id: ObjectId;
  name: string;
  parent_id: string;
  is_enabled: boolean;
  image?: string | null;
}) => Promise<boolean | string>;

export type ContentHookFn = {
  insert: ContentInsertFn;
  update: ContentUpdateFn;
  remove: (_id: ObjectId) => Promise<void>;
  addTag: InsertUserTagFn;
  removeTag: DeleteUserTagFn;
  // loading: boolean;
  list: ContentRow[];
};
