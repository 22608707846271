import { Grid } from '@mui/material';
import Iconify from 'minimal-ui/components/Iconify';
import { lazy } from 'react';
import { Loadable } from 'shared-web/routes';

import { _userInvoices } from 'minimal-ui/_mock';

import { TabHookInput, TabHookOutput } from 'shared-web/@types/tabs';
import { TagTypeUnion } from '@mymeeinc/types/tags';
import { ContentTypes } from '@mymeeinc/types/content';
import Sessions from 'shared-web/sections/member/Sessions';
import useSettings from 'minimal-ui/hooks/useSettings';
import { UserTypes } from '@mymeeinc/types/user';
import { MemberCustomDatum } from '@mymeeinc/types/customData';

export const useMemberProfileTabs = ({
  user,
}: TabHookInput & { user: MemberCustomDatum }): TabHookOutput => {
  const { appUserType } = useSettings();

  // const UserEditForm = Loadable(lazy(() => import('../components/MemberEditCoachList')));
  const MemberEditCoachList = Loadable(lazy(() => import('../components/MemberEditCoachList')));
  const MemberTags = Loadable(lazy(() => import('shared-web/sections/member/MemberTags')));
  const Calendar = Loadable(lazy(() => import('shared-web/sections/Calendar')));
  // const Sessions = Loadable(lazy(() => import('shared-web/sections/member/Sessions')));
  const tabs = [
    {
      value: 'tracking',
      icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
      component: (
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <MemberTags
              key={'symptom_member_tags'}
              member={user}
              type={ContentTypes.Symptom}
              userSuggestedTags={(user?.tags_user_symptom || []) as Array<TagTypeUnion>}
              selectedTags={(user?.tags_symptom || []) as Array<TagTypeUnion>}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MemberTags
              key={'diagnosis_member_tags'}
              member={user}
              type={ContentTypes.Diagnosis}
              userSuggestedTags={(user?.tags_user_diagnosis || []) as Array<TagTypeUnion>}
              selectedTags={(user?.tags_diagnosis || []) as Array<TagTypeUnion>}
            />{' '}
          </Grid>
          <Grid item xs={12} md={6}>
            <MemberTags
              key={'food_member_tags'}
              member={user}
              type={ContentTypes.Food}
              selectedTags={(user?.tags_food || []) as Array<TagTypeUnion>}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MemberTags
              key={'day_to_day_member_tags'}
              member={user}
              type={ContentTypes.DayToDay}
              showOnlyRoot={true}
              selectedTags={(user?.tags_day_to_day || []) as Array<TagTypeUnion>}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      value: 'calendar',
      icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
      component: <Calendar />,
    },
    {
      value: 'sessions',
      icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
      component: <Sessions member={user} />,
    },
  ];

  if (appUserType === UserTypes.ADMIN) {
    tabs.push({
      value: 'Coach',
      icon: <Iconify icon={'eva:person-fill'} width={20} height={20} />,
      component: (
        <div>
          <MemberEditCoachList />
        </div>
      ),
    });
  }

  return tabs;
};
