// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  label: string;
  disabled?: boolean;
};

type Props = IProps; // & DatePickerProps<any, any>;

export default function RHFDate({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        field.value = field.value ?? null;
        return (
          <DatePicker renderInput={(params) => <TextField {...params} />} {...field} {...other} />
        );
      }}
    />
  );
}
