import { useState } from 'react';
// @mui
import { Button, DialogTitle, Grid } from '@mui/material';
// @types
import { Session } from '@mymeeinc/types/session';
import { MemberCustomDatum } from '@mymeeinc/types/customData';

import { DialogAnimate } from 'minimal-ui/components/animate';
import SessionEditForm from './SessionEditForm';
import MySessionsList from '../sessions/MySessionsList';

import { useSession } from '../../hooks/useSession';
import { useCoach } from '../../hooks/users/useUser';
import useSettings from 'minimal-ui/hooks/useSettings';
import { UserTypes } from '@mymeeinc/types/user';

type Props = {
  member: MemberCustomDatum;
};

export default function Sessions({ member }: Props) {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [session, setSession] = useState<Session>();
  const { appUserType } = useSettings();
  const { user: coach } = useCoach(member.coach_id, 'MySessionsList');

  const {
    list: sessionList,
    insert: insertSession,
    update: updateSession,
    remove: removeSession,
  } = useSession(member.member_id);

  return (
    <Grid item xs={12} md={12}>
      {appUserType === UserTypes.ADMIN && (
        <Grid container sx={{ justifyContent: 'flex-end', p: 3 }}>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setSession(undefined);
              setIsOpenModal(true);
            }}
          >
            Add session
          </Button>
        </Grid>
      )}

      <Grid item xs={12} md={12}>
        <MySessionsList
          member={member}
          sessionList={sessionList}
          onEdit={(session) => {
            setSession(session);
            setIsOpenModal(true);
          }}
        />
      </Grid>
      <DialogAnimate
        open={isOpenModal}
        onClose={() => {
          setSession(undefined);
          setIsOpenModal(false);
        }}
      >
        <DialogTitle>{!session ? 'Add' : 'Edit'} session</DialogTitle>
        <SessionEditForm
          member={member}
          coach={coach}
          session={session}
          onCancel={() => {
            setSession(undefined);
            setIsOpenModal(false);
          }}
          addSession={insertSession}
          updateSession={updateSession}
          removeSession={removeSession}
        />
      </DialogAnimate>
    </Grid>
  );
}
