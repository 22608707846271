import { Button, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';
import { FormProvider } from 'minimal-ui/components/hook-form';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { forwardRef, ReactNode, useImperativeHandle } from 'react';

type Props = {
  formShape?: any;
  openConfirm: any;
  onCloseConfirm: any;
  onSubmit: any;
  question: ReactNode;
  info?: ReactNode;
  extraFields?: ReactNode;
  defaultValues?: any;
};
export type ConfirmationDialogRef = { reset: () => void };

const ConfirmationDialog = forwardRef(
  (
    {
      formShape = {},
      openConfirm,
      onCloseConfirm,
      onSubmit,
      question,
      info = null,
      extraFields = null,
      defaultValues = {},
    }: Props,
    ref
  ) => {
    const LoginSchema = Yup.object().shape(formShape);

    const methods = useForm<any>({
      resolver: yupResolver(LoginSchema),
      defaultValues,
    });

    const {
      reset,
      handleSubmit,
      formState: { isSubmitting },
    } = methods;

    useImperativeHandle(ref, () => ({
      reset() {
        reset();
      },
    }));

    return (
      <Dialog fullWidth maxWidth="xs" open={openConfirm} onClose={onCloseConfirm}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            <Typography gutterBottom>{question}</Typography>
            {info}
          </DialogTitle>
          {extraFields}
          <DialogActions>
            <Button
              disabled={isSubmitting}
              variant="outlined"
              color="inherit"
              onClick={onCloseConfirm}
            >
              No
            </Button>
            <LoadingButton type="submit" color="error" variant="contained" loading={isSubmitting}>
              Yes, I am sure
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    );
  }
);
export default ConfirmationDialog;
