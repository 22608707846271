import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Stack, Button, DialogActions, Tooltip, IconButton, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// redux
// components
import { FormProvider, RHFDate, RHFSelect, RHFTextField } from 'minimal-ui/components/hook-form';
import {
  InsertSessionFn,
  UpdateSessionFn,
  Session,
  RemoveSessionFn,
  UpdateSessionInput,
  SessionStatuses,
  InsertSessionInput,
} from '@mymeeinc/types/session';
import { CoachCustomDatum, MemberCustomDatum } from '@mymeeinc/types/customData';
import Iconify from 'minimal-ui/components/Iconify';
import useSettings from 'minimal-ui/hooks/useSettings';
import { UserTypes } from '@mymeeinc/types/user';

type FormValuesProps = {
  expiration_date: Date | null;
  created_by_name: string;
  member_name: string;
  coach_name: string;
  status: string;
};

type Props = {
  member: MemberCustomDatum;
  coach: CoachCustomDatum | null;
  session?: Session;
  onCancel: VoidFunction;
  addSession: InsertSessionFn;
  updateSession: UpdateSessionFn;
  removeSession: RemoveSessionFn;
};

export default function SessionEditForm({
  session,
  member,
  coach,
  onCancel,
  addSession,
  updateSession,
  removeSession,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { appUserType } = useSettings();

  const { member_id } = member;
  const { coach_id, first_name: coach_first_name, last_name: coach_last_name } = coach || {};
  const { _id } = session ?? {};
  const isCreating = _id === undefined;

  const SessionSchema = Yup.object().shape({
    expiration_date: Yup.date().nullable(),
    status: Yup.string().required(),
    // created_by_name: Yup.string(),
    // member_name: Yup.string(),
    // coach_name: Yup.string(),
    // status_name: Yup.string(),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(SessionSchema),
    defaultValues: {
      expiration_date: session?.expiration_date ?? null,
      created_by_name: 'ozby',
      member_name: [member.first_name, member.last_name].join(' '),
      coach_name: [coach_first_name, coach_last_name].join(' '),
      status: session?.status ?? 'Unscheduled',
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const _onSubmit = async (data: FormValuesProps) => {
    try {
      console.log({ data, _id });
      let result;
      if (_id) {
        result = await updateSession({ _id, member_id, coach_id, ...data } as UpdateSessionInput);
      } else {
        result = await addSession({
          member_id,
          coach_id,
          ...data,
        } as InsertSessionInput);
      }

      if (result.success) {
        if (isCreating) {
          enqueueSnackbar('Create success!');
        } else {
          enqueueSnackbar('Update success!');
        }
      } else {
        enqueueSnackbar('Unable to session user! ' + result.msg, { variant: 'error' });
      }
      onCancel();
      reset();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Unable to session user! ' + error.message, { variant: 'error' });
    }
  };

  const handleDelete = async () => {
    if (!_id) return;
    try {
      const response = await removeSession({ _id });
      if (response.success) {
        enqueueSnackbar('Delete success!');
      } else {
        enqueueSnackbar('Unable to delete! ' + response.msg, { variant: 'error' });
      }
      onCancel();
    } catch (error) {
      console.error(error);
    }
  };
  const statuses = Object.entries(SessionStatuses).map(([, b]) => b);
  const disableSelect = (option: SessionStatuses) =>
    [
      SessionStatuses.CANCELED,
      // SessionStatuses.SCHEDULED,
      // SessionStatuses.COMPLETED,
      // SessionStatuses.UNSCHEDULED,
      SessionStatuses.EXPIRED,
    ].includes(option);
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(_onSubmit)}>
      <Stack spacing={2} sx={{ p: 3 }}>
        <RHFTextField name="member_name" label="Member" disabled />
        <RHFTextField name="coach_name" label="Coach" disabled />
        <RHFSelect
          fullWidth
          name="status"
          label="Status"
          InputLabelProps={{ shrink: true }}
          SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
          disabled={disableSelect(session?.status as SessionStatuses)}
        >
          {statuses.map((option) => (
            <MenuItem
              key={option}
              value={option}
              disabled={disableSelect(option)}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option}
            </MenuItem>
          ))}
        </RHFSelect>
        <RHFDate
          name="expiration_date"
          label="Expiration date (optional)"
          disabled={appUserType !== UserTypes.ADMIN}
        />
        {!isCreating && (
          <>
            {/*<RHFTextField name="created_by_name" label="Created by" disabled />*/}
            {/*<RHFTextField name="status_name" label="Status" disabled />*/}
            <RHFDate name="created_at" label="Creation date" disabled />
          </>
        )}
      </Stack>

      <DialogActions>
        {!isCreating && (
          <Tooltip title="Delete Session">
            <IconButton onClick={handleDelete}>
              <Iconify icon="eva:trash-2-outline" width={20} height={20} />
            </IconButton>
          </Tooltip>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {isCreating ? 'Add' : 'Edit'}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
