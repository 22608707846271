// @mui
import {
  Box,
  Card,
  CardProps,
  Divider,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tabs,
} from '@mui/material';
// components
import Scrollbar from 'minimal-ui/components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'minimal-ui/components/table';
import { useRealmApp } from '../../components/RealmApp';
import { Session, SessionStatuses } from '@mymeeinc/types/session';
import MySessionsListRow from './MySessionsListRow';
import { MemberCustomDatum } from '@mymeeinc/types/customData';
import useTable, { getComparator } from 'minimal-ui/hooks/useTable';
import { UserTypes } from '@mymeeinc/types/user';
import useSettings from 'minimal-ui/hooks/useSettings';
import useTabs from 'minimal-ui/hooks/useTabs';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  member?: MemberCustomDatum;
  sessionList: Session[];
  onEdit?: (session: Session) => void;
}
type FilterTab = [string, (row: Session) => boolean];

export default function MySessionsList({ member, sessionList = [], onEdit, ...other }: Props) {
  const { user } = useRealmApp();
  const { appUserType } = useSettings();
  const { page, order, orderBy, rowsPerPage, setPage, onChangePage, onChangeRowsPerPage } =
    useTable({ defaultRowsPerPage: 10 });
  const { currentTab: filterField, onChangeTab: onChangeFilterStatus } = useTabs('all');

  if (!member) {
    member = user;
  }
  const tableLabels = [
    { id: 'session', label: '#' },
    { id: 'acuity.datetime', label: 'Scheduled date', sx: { textAlign: 'center' } },
    { id: 'expiration_date', label: 'Expiration date', sx: { textAlign: 'center' } },
    { id: 'coach', label: 'Coach' },
    { id: 'status', label: 'Status' },
    { id: 'acuity', label: '' },
    { id: 'acuity-cancel', label: '' },
  ];
  let filters: FilterTab[];
  if (appUserType !== UserTypes.MEMBER) {
    tableLabels.push({ id: 'manage', label: '' });
  }

  const groupedStatuses: FilterTab[] = [
    ['Completed', ({ status }: Session) => [SessionStatuses.COMPLETED].includes(status)],
    ['Scheduled', ({ status }: Session) => [SessionStatuses.SCHEDULED].includes(status)],
    [
      'Missed',
      ({ status }: Session) =>
        [
          SessionStatuses.COACH_MISSED,
          SessionStatuses.CLIENT_MISSED,
          SessionStatuses.CANCELED,
        ].includes(status),
    ],
    ['Expired', ({ status }: Session) => [SessionStatuses.EXPIRED].includes(status)],
  ];

  filters = [['all', () => true], ...groupedStatuses];

  const dataFiltered = applySortFilter({
    tableData: sessionList,
    comparator: getComparator(order, orderBy),
    filterField,
    filters,
  });

  return (
    <Card {...other}>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={filterField}
        onChange={(event, value) => {
          setPage(0);
          onChangeFilterStatus(event, value);
        }}
        sx={{ px: 2, bgcolor: 'background.neutral' }}
      >
        {filters.map(([tab, filter]) => {
          const count = sessionList.filter(filter).length;
          return <Tab disableRipple key={tab} label={`${tab} (${count})`} value={tab} />;
        })}
      </Tabs>

      <Divider />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 720, p: 3 }}>
          <Table size="small">
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {dataFiltered
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((session, sessionNo) => (
                  <MySessionsListRow
                    key={session._id.toString()}
                    sessionNo={sessionNo}
                    session={session}
                    member={member}
                    onEdit={onEdit}
                  />
                ))}
              {/*<TableEmptyRows height={52} emptyRows={emptyRows(1, 5, tableData.length)} />*/}
              <TableNoData
                isNotFound={
                  dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .length === 0
                }
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Divider />

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataFiltered.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </Card>
  );
}

function applySortFilter({
  tableData,
  comparator,
  filterField,
  filters,
}: {
  tableData: Session[];
  comparator: (a: any, b: any) => number;
  filterField: string;
  filters: FilterTab[];
}): Session[] {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  const [_filterName, _filterMethod] =
    filters.find(([filterName]) => filterName === filterField) || [];
  if (_filterMethod) {
    tableData = tableData.filter(_filterMethod);
  }

  return tableData;
}
